//global font-size (small, medium, large)
// main {
//   font-size: rem-calc(18);
//   @include breakpoint(medium) {
//     font-size: rem-calc(21);
//   }
//   @include breakpoint(large) {
//     font-size: rem-calc(24);
//   }
// }

@mixin typo-base {
  //Dynamic Font Size (mobile, desktop)
  @include font-size-pt(26*0.75, 26);
  font-weight: 400;
  letter-spacing: 0em;
  line-height:normal;
}

@mixin typo-menu {
  @include font-size-pt(21*0.75, 21);
  font-weight: 700;
  letter-spacing: 0em;
  line-height:normal;
}

@mixin typo-footer {
  @include font-size-pt(21*0.75, 21);
  font-weight: 400;
  letter-spacing: 0em;
  line-height:normal;

  a:hover {
    color:$graytext;
  }
}

@mixin typo-lead {
  @include font-size-pt(40*0.5, 40);
  font-weight: 400;
  letter-spacing: 0em;
  line-height:1.25;
}

body {
  font-family:'HelveticaNow', sans-serif;
  @include typo-base;
  -moz-osx-font-smoothing: grayscale;
  color:$textcolor;
}
a {
  color:inherit;
  text-decoration: none;
}
a:focus {
  outline: none;
}

@mixin typo-h1 {
  @include font-size-pt(40*0.5, 40);
  font-weight: 400;
}
@mixin typo-h2 {
  @include font-size-pt(40*0.5, 40);
  font-weight: 400;
}
@mixin typo-h3 {
  font:inherit;
}
@mixin typo-h4 {
  font:inherit;
}

strong {
  font-weight: 700;
}

address {
  font:unset; //remove italic browser style
}

p {
  margin:0 0 1em 0;
}
p:last-child {
  margin-bottom:0;
}
p a {
  //text-decoration: underline;
  //text-decoration-thickness: 1px;
}

h1 {
  @include typo-h1;
  margin:0 0 1em 0;
}
h2 {
  @include typo-h2;
  margin:0 0 1em 0;
}
h3 {
  @include typo-h3;
  margin:0 0 1em 0;
}
h4 {
  @include typo-h4;
  margin:0;
}

@mixin text-decoration-underline {
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-underline-offset: 0.3em;
}

