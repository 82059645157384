.bl-herohome {
    position: relative;

    height:100vh;
    height:100lvh;
    &.layouthalfh {
        height:50vh;
        height:50lvh;
        min-height: rem-calc(470);
    }

    .ctrimage {
        @include absposfull;
        img {
            @include absposfull;
            object-fit: cover;
            object-position: center center;
        }
    }
    
    .ctrtext {
        position: absolute;
        left:10%;
        right:10%;
        max-width:24ch;
        margin-left: auto;
        margin-right: auto;
        bottom:25vh;

        .text {
            text-align: center;
            //text-shadow: 0 0 5px rgba(0,0,0,.05),0 0 22px rgba(0,0,0,.1);
            text-shadow: 0 0 8px rgba(0,0,0,0.8);
        }
    }
}

//typo
.bl-herohome {
    .ctrtext {
        @include font-size-pt(80*0.6, 80);
        @include breakpoint(xlarge) {
            font-size:rem-calc(96);
        }
        font-weight: 400;
        line-height:math.div(82, 60); 
        color:$white;        
    }
}

//animation
.bl-herohome .text {
    span {
        animation: blur-text 1.1s ease-in 0s 1 normal;
        animation-fill-mode: both;
    }
    @for $i from 1 through 20 {
		span:nth-child(#{$i}) {
            animation-delay: 1s + $i*0.3s;
        }
	}
}

@keyframes blur-text {
    0% {
        opacity: 0;
        filter: blur(8px);
    }
    100% {
    }
  }