.bl-textwithimage {
    //V1: normal
    .colimage {
        .minhsizer {
            display:none;
        }
        img {
            @include w100bl;
        }
    }
    /*
    //V2: dynamic image height
    .colimage {
        position: relative;
        .minhsizer {
            aspect-ratio: 1;
        }
        img {
            //dynamic height
            @include absposfull;
            object-fit: cover;
            object-position: center center;
        }
    }
    */
    .colorsnegative1 & .coltext {
        color:$black;
    }

    @include breakpoint(large) {
        .cols {
            @include grid;
            grid-template-columns: repeat(2, 1fr);
        }

        .coltext {
            /*
            //Text vertikal zentrieren
            display:flex;
            justify-content: center;
            align-items: center;
            */
        }
        .text {
        }

        &.imgposr {
            .coltext {
                order: 1;
            }
            .colimage {
                order: 2;
            }
        }
        &.imgposl {
            .colimage {
                order: 1;
            }
            .coltext {
                order: 2;
            }
        }
    }
    @include breakpoint(medium down) {
        .text {
            padding-top:2em;
            padding-bottom:2em;
            padding-left: var(--lrmargin);
            padding-right: var(--lrmargin);
        }
    }

    .copyright {
        padding:0.3em 0.2em;
        position: absolute;
        bottom: 0;
    }
}

//typo
.bl-textwithimage {
    .text a {
        @include text-decoration-underline;
    }
}