.bl-imagelinksvar {
    .items {
        @include grid;
        grid-template-columns: repeat(10, 1fr);
        gap:10px;

        align-items: start;

        @include breakpoint(medium down) {
            grid-template-columns: 1fr;
        }
    }
    .item {
        @include breakpoint(large) {
            &.imgwidth40 {
                grid-column: span 4;
            }
            &.imgwidth50 {
                grid-column: span 5;
            }
            &.imgwidth60 {
                grid-column: span 6;
            }
            &.imgwidth80 {
                grid-column: span 8;
            }
        }

        position: relative;
        img {
            @include w100bl;
        }
        
        .ctroverlay {
            @include absposfull;
            //background-color:rgba(0, 0, 0, 0.3);

            display: flex;
            justify-content: center;
            align-items: center;

            h2 {
                margin-bottom: 0;
            }
        }

        overflow: hidden;
        img {
            transition: transform .4s;
        }
        &:hover {
            img {
                transform: scale(1.05);
            }
        }
    }
}

//typo
.bl-imagelinksvar {
    h2 {
        @include font-size-pt(60*0.6, 60);
        font-weight: 700;
        color:$white;
        margin:0;
        text-align: center;
        text-shadow: 0 0 8px rgba(0,0,0,0.8);
    }
}
.bl-imagelinksvar.textsizesmall {
    h2 {
        @include font-size-pt(40, 40);
    }
}