.buttonbar {}

.button {
  display: block;
  width: fit-content;
  padding: 0.45em 1em 0.25em 1em;
  color: $buttonfg;
  //@include borderradius2;
  background-color: $buttonbg;
  
  cursor: pointer;
}
button.button {
  //remove default <button> styling
  border:none;
  outline: none;
}

.button {
  transition: background-color 0.2s;

  &:hover {
    color: $buttonfg-hover;
    background-color: $buttonbg-hover;
  }
}

//typo
.button {
  font:inherit;
  //@include font-size-pt(23*0.75, 23);
  //font-weight: 700;
  //line-height: math.div(31, 23);
}