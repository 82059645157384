html, body {
  overflow-x: clip;
}

body {
  margin:0;
  padding:0;

  background-color: $white;
}

/*
body.topmargin1 {
  @include rem-calc-sl(margin-top, 125, 0.6);
}
*/