footer {
  background-color: $darkgreen;
  clip-path: polygon(0% var(--diagcliph), 100% 0%, 100% 100%, 0% 100%);

  color:$black;

  @include rem-calc-sl(padding-top, 120, 0.5);
  @include rem-calc-sl(padding-bottom, 50, 0.5);

  @include breakpoint(large) {
    .ctrcols {
      display: flex;
      justify-content: space-between;
    }
  }
  @include breakpoint(medium down) {
    .col2, .col3 {
      margin-top: 2em;
    }
  }

  .ctrsocial {
    margin-top:2em;
    
    display:flex;
    gap:1.25em;

    .icon {
      @include rem-calc-sl(height, 32, 1);
    }
  }
}

//typo
footer {
  @include typo-footer;
}